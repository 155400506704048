import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import CaseLogo from "../components/caseLogo";
import { CaseList } from "../components/caseList";

const Referenzen = () => {
  const images = useStaticQuery(graphql`
    query {
      gasag: file(relativePath: { eq: "logos/gasag.png" }) {
        publicURL
      }
      gkv: file(relativePath: { eq: "logos/gkv.png" }) {
        publicURL
      }
      amazone: file(relativePath: { eq: "logos/amazone.png" }) {
        publicURL
      }
      messemuenchen: file(relativePath: { eq: "logos/messe-muenchen.png" }) {
        publicURL
      }
      ssi: file(relativePath: { eq: "logos/ssi.png" }) {
        publicURL
      }
      helaba: file(relativePath: { eq: "logos/helaba.png" }) {
        publicURL
      }
      mercedesbenz: file(relativePath: { eq: "logos/mercedes-benz.png" }) {
        publicURL
      }
      schmotzer: file(relativePath: { eq: "logos/schmotzer.png" }) {
        publicURL
      }
      boots: file(relativePath: { eq: "logos/boots.png" }) {
        publicURL
      }
      bosch: file(relativePath: { eq: "logos/bosch.png" }) {
        publicURL
      }
      tchibo: file(relativePath: { eq: "logos/tchibo.png" }) {
        publicURL
      }
      bitmarck: file(relativePath: { eq: "logos/bitmarck.png" }) {
        publicURL
      }
      nuernberger: file(relativePath: { eq: "logos/nuernberger.png" }) {
        publicURL
      }
      nergie: file(relativePath: { eq: "logos/n-ergie.png" }) {
        publicURL
      }
      liebherr: file(relativePath: { eq: "logos/liebherr.png" }) {
        publicURL
      }
      hagebau: file(relativePath: { eq: "logos/hagebau.png" }) {
        publicURL
      }
      dmdrogeriemarkt: file(
        relativePath: { eq: "logos/dm-drogeriemarkt.png" }
      ) {
        publicURL
      }
      ottogroup: file(relativePath: { eq: "logos/otto-group.png" }) {
        publicURL
      }
      guardian: file(relativePath: { eq: "logos/guardian.png" }) {
        publicURL
      }
      tui: file(relativePath: { eq: "logos/tui.png" }) {
        publicURL
      }
      blume2000: file(relativePath: { eq: "logos/blume2000.png" }) {
        publicURL
      }
      fressnapf: file(relativePath: { eq: "logos/fressnapf.png" }) {
        publicURL
      }
      soskinderdorf: file(relativePath: { eq: "logos/sos-kinderdorf.png" }) {
        publicURL
      }
      aachenmuenchener: file(
        relativePath: { eq: "logos/aachenmuenchener.png" }
      ) {
        publicURL
      }
      astrazeneca: file(relativePath: { eq: "logos/astrazeneca.png" }) {
        publicURL
      }
      mhplus: file(relativePath: { eq: "logos/mhplus.png" }) {
        publicURL
      }
      swissinfo: file(relativePath: { eq: "logos/swissinfo.png" }) {
        publicURL
      }
      haufe: file(relativePath: { eq: "logos/haufe.png" }) {
        publicURL
      }
    }
  `);

  return (
    <Layout lang="de" langPath="nav.references.link">
      <Seo
        title="Referenzen – Vertrauen, das wir genießen"
        description="Als Partner begleiten wir unsere Kunden über den gesamten Projektzyklus und begeistern kontinuierlich mit dem Mix aus fachlicher Kompetenz und familiärem Flair."
        keywords="Referenzen, Projekte, Kunden, Partner"
      />
      <div className="parallax-translate bg-shape-diamond"></div>
      <div className="container mt-4 mb-5">
        <div className="row">
          <div className="col-12">
            <h1 className="h5 text-color-monday-green">Referenzen</h1>
            <h2 className="text-size-2 text-size-md-1">
              Vertrauen, das wir genießen
            </h2>
            <p>
              Personalisierung, Markenkonsistenz, Effizienzsteigerung –
              natürlich können wir unsere Kunden damit überzeugen. Aber es gibt
              noch mehr Gründe, warum uns nationale und internationale Konzerne
              seit Jahren die Entwicklung ihrer digitalen Plattformen
              anvertrauen. Es sind unsere Erfahrung und die partnerschaftliche
              Zusammenarbeit.
            </p>
            <p>
              Wir durften an vielen Projekten wachsen.
              <br />
              Hier stellen wir Ihnen einige davon vor.
            </p>
          </div>
        </div>
      </div>

      <div className="container mb-4 mb-md-5">
        <div className="row mb-3 reveal">
          <h2 className="col-12 text-center">Aktuelle Referenzen</h2>
        </div>
        <CaseList />
      </div>

      <div className="container">
        <div className="row mb-3 reveal">
          <h2 className="col-12 text-center">Weitere Referenzen</h2>
        </div>

        <div className="row">
          {Object.values(images).map((image, i) => (
            <CaseLogo key={i} {...{ image }} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Referenzen;
